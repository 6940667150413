import React, { Fragment } from 'react';
import ApplicationsGrid from '../../components/ApplicationsGrid/ApplicationsGrid';
import logoThales from '../../assets/images/thales-logo-white.svg';
import { Application } from '../../constants';
import './LandingPage.css';

const LandingPage = () => (
  <Fragment>
    <div className="landing-page">
      <div className="landing-page-header">
        <img
          src={logoThales}
          className="logo-ecosystem-color"
          alt="Thales"
        />
        <div className="landing-page-title">{Application.APPLICATION_NAME}</div>
      </div>
      <div className="landing-page-body">
        <div className="app-selection">
          <div className="selection-header">Select your application</div>
          <ApplicationsGrid />
        </div>
      </div>
    </div>
  </Fragment>
);

export default LandingPage;
