import { UserManager } from 'oidc-client';

import oidcConfig from './oidcConfig';

// to activate console logging for oidc
// import { Log } from 'oidc-client';
// Log.logger = console;

const userManager = new UserManager(oidcConfig);

export default userManager;
