import React from 'react';
import { AtfmTheme } from '@atfm/atfm-material-ui';
import UserMenu from '../UserMenu/UserMenu';
import LandingPage from '../../containers/LandingPage/LandingPage';
import './Layout.css';

const Layout = () => (
  <AtfmTheme>
    <div className="menu">
      <UserMenu />
    </div>
    <div className="content">
      <LandingPage />
    </div>
  </AtfmTheme>
);

export default Layout;
