import jwtDecode from 'jwt-decode';
import { ApplicationRoles } from '../constants';

export default class JwtUtils {
  static getLoginEventFromJwt(user) {
    if (user && user.access_token) {
      const env = window.env.EXECUTION_ENV;
      const accessCDM = ApplicationRoles.APP_CDM.replace('{env}', env);
      const decodedToken = jwtDecode(user.access_token);
      const event = {
        name: 'login',
        payload: {
          name: decodedToken.name || decodedToken.preferred_username || decodedToken.email,
          roles: decodedToken.roles,
          cdm: accessCDM in decodedToken,
        },
      };
      return event;
    }
    return null;
  }
}
