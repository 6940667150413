import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorHandler, StorageHelper } from '@atfm/utils';
import { useHistory } from 'react-router';
import JwtUtils from '../../utils/JwtUtils';
import userManager from '../../authentication/userManager';
import { addAccessTokenAndName } from '../../store/authentication/actions';

const LoginCallback = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loginSuccessCallback = async (user) => {
    const { access_token: accessToken, expires_at: expiresAt } = user;
    const { payload } = JwtUtils.getLoginEventFromJwt(user);

    await dispatch(addAccessTokenAndName({ accessToken, expiresAt, ...payload }));

    StorageHelper.storeItem('userRoles', payload.roles);
    StorageHelper.storeItem('userCDM', payload.cdm);
    history.push('/');
  };

  useEffect(async () => {
    try {
      const user = await userManager.signinRedirectCallback();
      loginSuccessCallback(user);
    } catch (err) {
      ErrorHandler.logError(err);
      history.replace('/login');
    }
  }, []);

  return null;
};

export default LoginCallback;
