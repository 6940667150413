import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ErrorHandler, StorageHelper } from '@atfm/utils';
import { addAccessTokenAndName } from '../../store/authentication/actions';

import userManager from '../../authentication/userManager';
import { Authentication } from '../../constants';

const TokenWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const refreshSuccessCallback = (user) => {
    if (user) {
      const name = StorageHelper.retrieveItem('name');

      const { access_token: accessToken, expires_at: expiresAt } = user;
      dispatch(addAccessTokenAndName({
        accessToken,
        expiresAt,
        name,
      }));
    }
  };

  const accessTokenExpireCallback = async () => {
    try {
      const user = await userManager.signinSilent();
      refreshSuccessCallback(user);
    } catch (err) {
      ErrorHandler.logError(err);
    }
  };

  useEffect(async () => {
    const tokenExpiry = StorageHelper.retrieveItem('ad_access_token_expiresAt') * 1000;
    const validityDiff = tokenExpiry - moment().valueOf();
    if (validityDiff <= 0) {
      window.location.assign(Authentication.LOGIN_REDIRECT);
    }

    userManager.events.addAccessTokenExpiring(accessTokenExpireCallback);
  }, []);

  return children;
};

TokenWrapper.propTypes = {
  children: PropTypes.node,
};

TokenWrapper.defaultProps = {
  children: (<></>),
};

export default TokenWrapper;
