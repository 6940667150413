import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import fetchUserInfo from '../../store/user/actions';
import Layout from '../../components/Layout/Layout';
import { useAppContext } from '../Context/AppContext';

const Home = () => {
  const appContext = useAppContext();
  const dispatch = useDispatch();

  useEffect(async () => {
    const [userInfo] = await Promise.all([dispatch(fetchUserInfo())]);
    appContext.setUserInfo(userInfo);
    appContext.setTfvName(userInfo.tfv);
  }, []);

  return (<Layout />);
};

export default Home;
