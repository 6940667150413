import { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorHandler } from '@atfm/utils';
import userManager from '../../authentication/userManager';

class SilentRefreshCallback extends Component {
  componentDidMount() {
    userManager.signinSilentCallback()
      .then(() => this.redirectToHome())
      .catch((err) => {
        ErrorHandler.logError(err);
      });
  }

  redirectToHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      null
    );
  }
}
SilentRefreshCallback.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default SilentRefreshCallback;
