import { ErrorHandler, StorageHelper, CustomError } from '@atfm/utils';
import { Authentication } from '../../constants';

const basePath = options => (options.basePath ? options.basePath : window.env.API_BASE_URL);

const handleErrors = (response) => {
  if (response.status === 401) {
    window.location.assign(Authentication.LOGIN_REDIRECT);
  } else if (!response.ok) {
    const formattedError = {
      status: response.status,
      statusText: response.statusText,
      url: response.url,
    };
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json().then((r) => {
        formattedError.message = r.message;
        throw new CustomError({ message: JSON.stringify(formattedError), body: r });
      });
    }
    throw new CustomError({ message: JSON.stringify(formattedError) });
  }
  return response;
};

const callApi = (path, options = {}) => {
  const accessToken = StorageHelper.retrieveItem('ad_access_token');
  return fetch(basePath(options) + path, {
    ...options,
    body: options.body
      ? JSON.stringify(options.body)
      : null,
    headers: {
      'Content-Type': (options.headers || {})['Content-Type'] || 'application/json',
      Authorization: accessToken,
      Accept: 'application/json',
      ...(options.headers || {}),
    },
  })
    .then(handleErrors)
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (contentType && (contentType.indexOf('application/json') !== -1)) {
        return response.json();
      }
      return response;
    })
    .catch((err) => {
      ErrorHandler.logError(err);
      throw err;
    });
};

export default callApi;
