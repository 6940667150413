import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorHandler } from '@atfm/utils';
import { useHistory } from 'react-router';
import userManager from '../../authentication/userManager';
import { logout } from '../../store/authentication/actions';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(logout());
    try {
      await userManager.signinRedirect();
    } catch (err) {
      ErrorHandler.logError(err);
      dispatch(logout());
      history.replace('/login');
    }
  }, []);

  return null;
};

export default LoginPage;
