/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { StorageHelper } from '@atfm/utils';

import {
  addAccessTokenAndName,
  addRefreshToken,
  logout,
} from './actions';

export const getInitialState = () => {
  const initialState = StorageHelper.getStorageInitialState() || {};
  const userRoles = StorageHelper.retrieveItem('userRoles');
  const authAppVersion = StorageHelper.retrieveItem('auth_app_version');

  const hasValidRoles = userRoles && userRoles.length > 0;
  const isSameAppVersion = authAppVersion === process.env.REACT_APP_HMI_VERSION;

  return {
    ...initialState,
    isLoggedIn: initialState.isLoggedIn && hasValidRoles && isSameAppVersion,
  };
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: {
    [addAccessTokenAndName.fulfilled](state) {
      state.isLoggedIn = true;
    },
    [addRefreshToken.fulfilled](state, { payload }) {
      state.refreshToken = payload;
    },
    [logout.pending]() {
      if (window.propagateSignout) window.propagateSignout();
    },
    [logout.fulfilled](state) {
      state.isLoggedIn = false;
    },
  },
});

export default sessionSlice.reducer;
