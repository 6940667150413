import { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorHandler } from '@atfm/utils';

class App extends Component {
  componentDidCatch(error) {
    ErrorHandler.logReactError(error);
  }

  render() {
    return this.props.children;
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
