/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Time } from '../../constants';
import fetchUserInfo from './actions';

const initialState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserInfo.fulfilled](state, { payload }) {
      state.uid = payload.uid;
      state.area = payload.area;
      state.tfv = payload.tfv;
      state.acc = payload.acc;
      state.secto = payload.secto;
      state.neighbours = payload.neighbours;
      state.entryStep = payload.entryStep / Time.MINUTE_IN_MILISECONDS;
      state.occStep = payload.occStep / Time.MINUTE_IN_MILISECONDS;
      state.entryDuration = payload.entryDuration / Time.MINUTE_IN_MILISECONDS;
      state.occDuration = payload.occDuration / Time.MINUTE_IN_MILISECONDS;
    },
    [fetchUserInfo.rejected](state, { payload }) {
      state.error = payload;
    },
  },
});

export default userSlice.reducer;
