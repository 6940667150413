/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState } from 'react';
import { StorageHelper } from '@atfm/utils';
import ADMIN from '../../assets/images/administration.svg';
import CDM from '../../assets/images/cdm.svg';
import POST_OPS from '../../assets/images/post-ops.svg';
import PRE_TACTICAL from '../../assets/images/pre-tactical.svg';
import REGULATIONS from '../../assets/images/regulations.svg';
import STATUS from '../../assets/images/status.svg';
import HELP from '../../assets/images/help.svg';
import TACTICAL from '../../assets/images/tactical.svg';
import SEQ from '../../assets/images/sequencer.svg';
import GREEN from '../../assets/images/green-ops.svg';
import BETA from '../../assets/images/beta.svg';
import { Application, ApplicationPaths, ApplicationRoles, CdmPaths } from '../../constants';
import './ApplicationsGrid.css';

const ApplicationsGrid = () => {
  const [postOpsClicked, setPostOpsClicked] = useState(false);
  const [tacticalClicked, setTacticalClicked] = useState(false);
  const [preTacticalClicked, setPreTacticalClicked] = useState(false);
  const [regulationsClicked, setRegulationsClicked] = useState(false);
  const [statusClicked, setStatusClicked] = useState(false);
  const [userGuideClicked, setUserGuideClicked] = useState(false);
  const [adminClicked, setAdminClicked] = useState(false);
  const [cdmClicked, setCdmClicked] = useState(false);
  const [seqClicked, setSeqClicked] = useState(false);
  const [greenClicked, setGreenClicked] = useState(false);

  const userRoles = StorageHelper.retrieveItem('userRoles');
  const userCDM = StorageHelper.retrieveItem('userCDM');

  return (
    <Fragment>
      <div className="selection-grid">
        {/* TACTICAL */}
        {userRoles.includes(ApplicationRoles.APP_TACTICAL) &&
          <div
            className={`${tacticalClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setTacticalClicked(true)}
            onMouseUp={() => setTacticalClicked(false)}
          >
            <a
              className={Application.APP_TACTICAL}
              href={ApplicationPaths.APP_TACTICAL}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={TACTICAL}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_TACTICAL}
              </div>
            </a>
          </div>
        }
        {/* SEQUENCER */}
        {userRoles.includes(ApplicationRoles.APP_SEQ) &&
          <div
            className={`${seqClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setSeqClicked(true)}
            onMouseUp={() => setSeqClicked(false)}
          >
            <a
              className={Application.APP_SEQ}
              href={ApplicationPaths.APP_SEQ}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={SEQ}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_SEQ}
              </div>
            </a>
          </div>
        }
        {/* POST-OPS */}
        {userRoles.includes(ApplicationRoles.APP_POSTOPS) &&
          <div
            className={`${postOpsClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setPostOpsClicked(true)}
            onMouseUp={() => setPostOpsClicked(false)}
          >
            <a
              className={Application.APP_POSTOPS}
              href={ApplicationPaths.APP_POSTOPS}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={POST_OPS}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_POSTOPS}
              </div>
            </a>
          </div>
        }
        {/* GREEN */}
        {(userRoles.includes(ApplicationRoles.APP_GREEN_AIR) || userRoles.includes(ApplicationRoles.APP_GREEN_FIR)) &&
          <div
            className={`${greenClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setGreenClicked(true)}
            onMouseUp={() => setGreenClicked(false)}
          >
            <a
              className={Application.APP_GREEN}
              href={window.env.EXECUTION_ENV === 'prod' ? ApplicationPaths.APP_GREEN_PROD : ApplicationPaths.APP_GREEN_STAG}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={GREEN}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_GREEN}
              </div>
            </a>
          </div>
        }
        {/* PRE-TACTICAL */}
        {userRoles.includes(ApplicationRoles.APP_PRETACT) &&
          <div
            className={`${preTacticalClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setPreTacticalClicked(true)}
            onMouseUp={() => setPreTacticalClicked(false)}
          >
            <a
              className={Application.APP_PRETACT}
              href={ApplicationPaths.APP_PRETACT}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-group-square">
                <img
                  src={BETA}
                  className="beta-icon"
                  alt="Thales"
                />
                <img
                  src={PRE_TACTICAL}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_PRETACT}
              </div>
            </a>
          </div>
        }
        {/* REGULATIONS */}
        {userRoles.includes(ApplicationRoles.APP_REGULATIONS) &&
          <div
            className={`${regulationsClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setRegulationsClicked(true)}
            onMouseUp={() => setRegulationsClicked(false)}
          >
            <a
              className={Application.APP_REGULATIONS}
              href={ApplicationPaths.APP_REGULATIONS}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={REGULATIONS}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_REGULATIONS}
              </div>
            </a>
          </div>
        }
        {/* ADMIN */}
        {userRoles.includes(ApplicationRoles.APP_ADMIN) &&
          <div
            className={`${adminClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setAdminClicked(true)}
            onMouseUp={() => setAdminClicked(false)}
          >
            <a
              className={Application.APP_ADMIN}
              href={ApplicationPaths.APP_ADMIN}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={ADMIN}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_ADMIN}
              </div>
            </a>
          </div>
        }
        {/* STATUS */}
        <div
          className={`${statusClicked ? 'item-clicked' : 'item'}`}
          onMouseDown={() => setStatusClicked(true)}
          onMouseUp={() => setStatusClicked(false)}
        >
          <a
            className={Application.APP_STATUS}
            href={ApplicationPaths.APP_STATUS}
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-square">
              <img
                src={STATUS}
                className="app-icon"
                alt="Thales"
              />
            </div>
            <div className="app-description">
              {Application.APP_STATUS}
            </div>
          </a>
        </div>
        {/* CDM */}
        {userCDM &&
          <div
            className={`${cdmClicked ? 'item-clicked' : 'item'}`}
            onMouseDown={() => setCdmClicked(true)}
            onMouseUp={() => setCdmClicked(false)}
          >
            <a
              className={Application.APP_CDM}
              href={ApplicationPaths.APP_CDM + CdmPaths.CDM_SSO}
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon-square">
                <img
                  src={CDM}
                  className="app-icon"
                  alt="Thales"
                />
              </div>
              <div className="app-description">
                {Application.APP_CDM}
              </div>
            </a>
          </div>
        }
        {/* User Guide */}
        <div
          className={`${userGuideClicked ? 'item-clicked' : 'item'}`}
          onMouseDown={() => setUserGuideClicked(true)}
          onMouseUp={() => setUserGuideClicked(false)}
        >
          <a
            className={Application.APP_HELP}
            href={ApplicationPaths.APP_HELP}
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-square">
              <img
                src={HELP}
                className="app-icon"
                alt="Thales"
              />
            </div>
            <div className="app-description">
              {Application.APP_HELP}
            </div>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default ApplicationsGrid;
