import React from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import selectIsLoggedIn from '../../store/authentication/selectors';

const LOGIN_ROUTE = '/login';

export const PrivateRouteComponent = (props) => {
  const { component: Component, isLoggedIn, ...rest } = props;
  return (
    <Route
      {...rest}
      render={() => (isLoggedIn
        ? <Component {...props} />
        : <Redirect to={LOGIN_ROUTE} />)}
    />
  );
};

PrivateRouteComponent.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element, // if component is class component
    PropTypes.elementType, // if component is stateless functional component
  ]).isRequired,
  isLoggedIn: PropTypes.bool,
};

PrivateRouteComponent.defaultProps = {
  isLoggedIn: false,
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isLoggedIn: selectIsLoggedIn(state),
});

const mergeProps = (stateProps, dispatchProps, props) => ({
  ...stateProps,
  ...dispatchProps,
  ...props,
});

export default withRouter(connect(mapStateToProps, null, mergeProps, { pure: true })(PrivateRouteComponent));
