import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StorageHelper } from '@atfm/utils';
import { logout } from '../../store/authentication/actions';

import './UserMenu.css';

const UserMenu = () => {
  const wrappeLogoutrRef = useRef();
  const dispatch = useDispatch();

  const [showUserMenu, setShowUserMenu] = useState(false);

  const toggleUserMenuVisibility = () => {
    setShowUserMenu(!showUserMenu);
  };

  const retrieveUserName = () => StorageHelper.retrieveItem('name');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrappeLogoutrRef && showUserMenu && !wrappeLogoutrRef.current?.contains(event.target)) {
        toggleUserMenuVisibility();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className="user-menu-switch">
      <div
        className={`user-menu-button option ${showUserMenu ? 'active-usermenu' : 'inactive'}`}
        id="user-menu-button"
        tabIndex="0"
        onClick={() => { toggleUserMenuVisibility(); }}
        onKeyDown={() => { }}
        role="button"
      >
        <label> {retrieveUserName()} </label>
        <i id="user-menu-icon-user-expand" className="icon-arrow_down" />
      </div>
      {showUserMenu &&
        <div ref={wrappeLogoutrRef} className="user-menu">
          <div className="arrow-up" />
          <div className="user-menu-link menu-account">
            <i className="user-menu-icon icon-account_circle" />
            <a href={`${window.env.OIDC_AUTHORITY}/account/`} target="_blank" rel="noopener noreferrer" className="hyperlink-user-menu">
              Manage Account
            </a>
          </div>
          <div className="user-menu-seperator" />
          <div
            className="user-menu-link menu-logout"
            onClick={() => { dispatch(logout()); }}
            onKeyDown={() => { }}
            tabIndex="0"
            role="button"
          >
            <i className="user-menu-icon icon-log_out" />
            <div>Log Out</div>
          </div>
        </div>
      }
    </div>
  );
};

export default UserMenu;
