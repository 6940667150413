import React from 'react';
import { Route, Switch } from 'react-router';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import App from './containers/App/App';
import TokenWrapper from './containers/TokenWrapper/TokenWrapper';
import Home from './containers/Home/Home';
import LoginCallback from './containers/AuthCallback/LoginCallback';
import LoginPage from './containers/LoginPage/LoginPage';
import SilentRefreshCallback from './containers/AuthCallback/SilentRefreshCallback';
import { AppProvider } from './containers/Context/AppContext';

const TokenWrappedHome = () => (
  <TokenWrapper>
    <Home />
  </TokenWrapper>
);

const routes = () => (
  <App>
    <AppProvider>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/oauth/login" component={LoginCallback} />
        <Route path="/oauth/refresh" component={SilentRefreshCallback} />
        <PrivateRoute path="*" component={TokenWrappedHome} />
      </Switch>
    </AppProvider>
  </App>
);

export default routes;
