import { createSelector } from '@reduxjs/toolkit';

const selectSession = state => state.session;

const selectIsLoggedIn = createSelector(
  selectSession,
  session => session?.isLoggedIn || false,
);

export default selectIsLoggedIn;
